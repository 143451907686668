import React, { useState } from 'react'

import { Box, ToggleButtonGroup, useMediaQuery } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import { GridApi } from 'ag-grid-community'
import { FLAG_FEATURES } from 'api/src/common/enums'
import {
  AncillaryTableQuery,
  AncillaryTableQueryVariables,
} from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { Metadata, useQuery } from '@redwoodjs/web'

import { TableFilterIconButton } from 'src/components/Ancil/common/TableFilterIconButton'
import TabNavigation, {
  TabsEnum,
} from 'src/components/Ancil/common/TabNavigation'
import { ANCIL_TABLE_QUERY } from 'src/components/Ancil/gql/queries'
import BulkReferLeadModal from 'src/components/Ancil/modals/BulkReferLeadModal'
import LeadDetailsModal from 'src/components/Ancil/modals/LeadDetailsModal'
import ReferLeadModal from 'src/components/Ancil/modals/ReferLeadModal'
import AncilTable from 'src/components/Ancil/table/AncilTable'
import BulkActionsPanel from 'src/components/Ancil/table/BulkActionsPanel'
import CSVDownloadButton from 'src/components/Ancil/table/CSVDownloadButton'
import FeatureDenied from 'src/components/Library/FeatureDenied/FeatureDenied'
import { SearchField } from 'src/components/Library/SearchField/SearchField'
import FeatureToggle from 'src/lib/hooks/LiveFeatureFlag/FeatureToggle'
import { useAuth } from 'src/Providers'

import PageHeader from '../../components/PageHeader/PageHeader'

export type AncillaryLead = AncillaryTableQuery['ancillaryLeads'][0]

const AncilPage = () => {
  const [activeLead, setActiveLead] = useState<AncillaryLead | null>(null)
  const [showActiveOnly, setShowActiveOnly] = useState(true)
  const [gridApi, setGridApi] = useState<GridApi<AncillaryLead> | null>(null)
  const [selectedLeadIds, setSelectedLeadIds] = useState<number[]>([])
  const [query, setQuery] = useState<string>('')
  const [isBulkReferModalOpen, setIsBulkReferModalOpen] =
    useState<boolean>(false)
  const queryParams = new URLSearchParams(location.search)
  const currentTab = queryParams.get('tab') || TabsEnum.NAME
  const { hasRole } = useAuth()
  const isSuperAdmin = hasRole(['SUPERADMIN'])

  const handleTabChange = (newTab: string) => {
    navigate(`${routes.ancil()}?tab=${newTab}`)
  }

  const { data, loading, error } = useQuery<
    AncillaryTableQuery,
    AncillaryTableQueryVariables
  >(ANCIL_TABLE_QUERY, {
    variables: { onlyActive: showActiveOnly },
  })

  const isSmallDisplay = useMediaQuery('(max-width:1500px)')

  return (
    <>
      <Metadata title="Referral Tracker" description="Referral Tracker" />
      <PageHeader title="Referral Tracker" />
      <FeatureToggle
        feature={FLAG_FEATURES.ANCIL}
        InactiveComponent={<FeatureDenied />}
      >
        <Box
          p={3}
          pb={2}
          className={'flex w-full items-center justify-between'}
        >
          <Box className={'flex'} gap={3}>
            <Box>
              <SearchField
                value={query}
                onChange={setQuery}
                className={'!w-[350px]'}
              />
            </Box>
            <Box className={'-mt-2'}>
              <TabNavigation tab={currentTab} onTabChange={handleTabChange} />
            </Box>
          </Box>
          {isSmallDisplay ? (
            <TableFilterIconButton
              showActiveOnly={showActiveOnly}
              setShowActiveOnly={setShowActiveOnly}
            />
          ) : (
            <Box display={'flex'} alignItems={'center'} gap={2}>
              <Box>
                <ToggleButtonGroup
                  value={showActiveOnly}
                  exclusive
                  onChange={(_e, newValue) => {
                    if (newValue !== null) {
                      gridApi.deselectAll()
                      setSelectedLeadIds([])
                      setShowActiveOnly(newValue)
                    }
                  }}
                >
                  <ToggleButton
                    disableRipple
                    value={true}
                    className={`px-4 py-0 capitalize leading-8 ${showActiveOnly ? '!bg-indigo-500 !text-white' : '!hover:bg-indigo-200 bg-white text-gray-900'}`}
                  >
                    Active Only
                  </ToggleButton>
                  <ToggleButton
                    disableRipple
                    value={false}
                    className={`px-4 py-0 capitalize leading-8 ${!showActiveOnly ? '!hover:bg-indigo-200 !bg-indigo-500 !text-white' : 'bg-white'}`}
                  >
                    All
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              {isSuperAdmin && (
                <Box>
                  <CSVDownloadButton gridApi={gridApi} />
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box p={3} pt={0}>
          <AncilTable
            ancillaryLeads={data?.ancillaryLeads || []}
            loading={loading}
            error={error}
            activeTab={currentTab as TabsEnum}
            query={query}
            setSelectedLeadIds={setSelectedLeadIds}
            onGridReady={setGridApi}
            setActiveLead={setActiveLead}
          />
        </Box>
        <BulkActionsPanel
          leads={data?.ancillaryLeads || []}
          deselectAll={gridApi?.deselectAll}
          selectedLeadIds={selectedLeadIds}
          setIsBulkReferModalOpen={setIsBulkReferModalOpen}
        />
        {activeLead && (
          <ReferLeadModal
            open={activeLead.leadStatus === 'NEW'}
            onClose={() => setActiveLead(null)}
            lead={activeLead}
          />
        )}
        {activeLead && (
          <LeadDetailsModal
            open={activeLead.leadStatus !== 'NEW'}
            onClose={() => setActiveLead(null)}
            lead={activeLead}
          />
        )}
        {!!selectedLeadIds.length && (
          <BulkReferLeadModal
            open={isBulkReferModalOpen}
            onClose={({ clearSelectedRows }) => {
              setIsBulkReferModalOpen(false)
              if (clearSelectedRows) gridApi?.deselectAll()
            }}
            leads={data?.ancillaryLeads?.filter((lead) =>
              selectedLeadIds.includes(lead.id),
            )}
          />
        )}
      </FeatureToggle>
    </>
  )
}

export default AncilPage
