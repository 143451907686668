export const ANCIL_TABLE_QUERY = gql`
  query AncillaryTableQuery($onlyActive: Boolean) {
    ancillaryLeads(onlyActive: $onlyActive) {
      id
      leadStatus
      leadCompany
      triggerType
      leadType
      triggeredByUser {
        id
        name
      }
      extPrimaryAgentName
      extSecondaryAgentName
      decryptedName
      propertyAddress
      createdAt
      triggeredAt
      processedAt
      formattedDate
      hasConsent
      additionalPayloadData
    }
  }
`

export const GET_LEAD_LOG = gql`
  query AncillaryLeadLog($leadId: Int!) {
    ancillaryLeadLog(ancillaryLeadId: $leadId) {
      id
      clientId
      ancillaryLeadId
      previousStatus
      newStatus
      createdAt
    }
  }
`
