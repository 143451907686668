import { LeadCompany, LeadType } from 'types/graphql'

// Will re-visit this utility when we know what further fields are
// required on lead submit

export const allowedCombinations: Record<LeadCompany, LeadType[]> = {
  ON_COVER: ['LANDLORD_INSURANCE', 'BUYER_INSURANCE', 'SELLER_INSURANCE'],
  BOND: ['BUYER_CONVEYANCING', 'SELLER_CONVEYANCING'],
  COMPARE_CONNECT: [
    'TENANT_CONNECTIONS',
    'BUYER_CONNECTIONS',
    'SELLER_CONNECTIONS',
  ],
  INNOVAYT: [
    'SELLER_FINANCE',
    'LANDLORD_FINANCE',
    'BUYER_FINANCE',
    'TENANT_FINANCE',
  ],
  RELLO: [],
}

export type AdditionalRequiredInformationType = {
  [K in keyof typeof allowedCombinations]: {
    [T in (typeof allowedCombinations)[K][number]]?: AdditionalRequirement[]
  }
}

export type AdditionalRequirement = {
  label: string
  dataType: 'STRING' | 'NUMBER' | 'DATE'
  dataKey: string
  required: boolean
}

// Use this to determine what additional data is required by the partner
// When passing additionalPayloadData when triggering the lead, pass it as a JSON that looks like this:
// {
//   dataKey: 'value',
//   dataKey2: 'value2',
// }
// Where dataKey is the key of the data required by the partner, defined above in the AdditionalRequiredInformationType type and
// value is the value of the data required by the partner
export const additionalRequiredInformation: AdditionalRequiredInformationType =
  {
    ON_COVER: {
      LANDLORD_INSURANCE: [
        {
          label: 'Weekly Rent',
          dataKey: 'weeklyRentAmount',
          required: true,
          dataType: 'NUMBER',
        },
      ],
      BUYER_INSURANCE: [],
      SELLER_INSURANCE: [],
    },
    BOND: {
      BUYER_CONVEYANCING: [],
      SELLER_CONVEYANCING: [],
    },
    COMPARE_CONNECT: {
      TENANT_CONNECTIONS: [],
      BUYER_CONNECTIONS: [],
      SELLER_CONNECTIONS: [],
    },
    INNOVAYT: {
      SELLER_FINANCE: [],
      LANDLORD_FINANCE: [],
      BUYER_FINANCE: [],
      TENANT_FINANCE: [],
    },
    RELLO: {},
  }
