import React from 'react'

import { Tabs, Tab } from '@mui/material'

interface Props {
  tab: string
  onTabChange: (newTab: string) => void
}

export enum TabsEnum {
  NAME = 'name',
  PROPERTY = 'property',
}

export default function TabNavigation({
  tab,
  onTabChange,
}: Props): React.ReactElement {
  const handleTabChange = (_, newValue: TabsEnum) => onTabChange(newValue)
  return (
    <Tabs value={tab} onChange={handleTabChange}>
      <Tab
        label={'Name'}
        value={TabsEnum.NAME}
        className={'capitalize'}
        disableRipple
      />
      <Tab
        label={'Property'}
        value={TabsEnum.PROPERTY}
        className={'capitalize'}
        disableRipple
      />
    </Tabs>
  )
}
