import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'

import { themeQuartz } from '@ag-grid-community/theming'
import { ApolloError } from '@apollo/client'
import { CursorArrowRaysIcon } from '@heroicons/react/24/outline'
import { Box, Typography, useMediaQuery } from '@mui/material'
import {
  ColDef,
  ICellRendererParams,
  GridApi,
  GridReadyEvent,
  GetRowIdParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import dayjs from 'dayjs'
import { LeadCompany, LeadStatus } from 'types/graphql'

import LeadStatusChip, {
  statusHumanNames,
} from 'src/components/Ancil/common/LeadStatusChip'
import OpportunityChip, {
  opportunityHumanNames,
} from 'src/components/Ancil/common/OpportunityChip'
import PartnerChip, {
  partnerHumanNames,
} from 'src/components/Ancil/common/PartnerChip'
import { TabsEnum } from 'src/components/Ancil/common/TabNavigation'
import Empty from 'src/components/Library/Empty'
import Loading from 'src/components/Library/Loading'
import { AncillaryLead } from 'src/pages/AncilPage/AncilPage'

import ActionCell from './ActionCell'
import EmptyValue from './EmptyValue'

const ancilGridTheme = themeQuartz.withParams({
  accentColor: '#6366F1',
  browserColorScheme: 'light',
  headerFontSize: 14,
  sidePanelBorder: true,
  wrapperBorder: true,
})

interface AncilTableProps {
  ancillaryLeads?: AncillaryLead[]
  loading?: boolean
  error?: ApolloError
  activeTab?: TabsEnum
  query?: string
  onGridReady?: (api: GridApi<AncillaryLead>) => void
  setSelectedLeadIds: Dispatch<SetStateAction<number[]>>
  setActiveLead: Dispatch<SetStateAction<AncillaryLead>>
}

const AncilTable: React.FC<AncilTableProps> = React.memo(
  function MemoizedAncilTable({
    ancillaryLeads,
    loading,
    error,
    activeTab = TabsEnum.NAME,
    query,
    onGridReady,
    setSelectedLeadIds,
    setActiveLead,
  }) {
    const [gridApi, setGridApi] = useState<GridApi<AncillaryLead> | null>(null)
    const isSmallDisplay = useMediaQuery('(max-width:1500px)')
    const hasSelectionRef = useRef(false)

    const reorderColumns = (api: GridApi<AncillaryLead>, tab: TabsEnum) => {
      if (!api) return

      if (tab === TabsEnum.NAME) {
        api.moveColumns(['decryptedName'], 1)
        api.moveColumns(['associatedAddress'], 3)
      } else if (tab === TabsEnum.PROPERTY) {
        api.moveColumns(['associatedAddress'], 1)
        api.moveColumns(['decryptedName'], 3)
      }
    }

    const colDefs: ColDef<AncillaryLead>[] = [
      {
        colId: 'decryptedName',
        headerName: 'Name',
        field: 'decryptedName',
        cellClass: 'truncate mt-2',
      },
      {
        colId: 'triggerType',
        headerName: 'Opportunity',
        field: 'triggerType',
        valueGetter: (params) => opportunityHumanNames[params.data.triggerType],
        flex: 1.1,
        cellRenderer: (params: ICellRendererParams<AncillaryLead>) => (
          <OpportunityChip opportunity={params.data.triggerType} />
        ),
      },
      {
        colId: 'associatedAddress',
        headerName: 'Associated Address',
        field: 'propertyAddress',
        cellClass: 'truncate mt-2',
        comparator: (a, b) => {
          const cleanString = (str: string) =>
            str.replace(/[\d\s\/]+/g, '').toLowerCase()
          const cleanA = cleanString(a)
          const cleanB = cleanString(b)
          return cleanA.localeCompare(cleanB)
        },
      },
      {
        colId: 'staffMembers',
        headerName: 'Staff Member(s)',
        field: 'extPrimaryAgentName',
        valueGetter: (params) =>
          `${params.data.extPrimaryAgentName}${
            params.data.extSecondaryAgentName
              ? `, ${params.data.extSecondaryAgentName}`
              : ''
          }`,
        cellRenderer: (params: ICellRendererParams<AncillaryLead>) => (
          <Box gap={2}>
            <Typography variant={'body2'}>
              {params.data.extPrimaryAgentName || (
                <Typography variant={'body2'} className={'text-gray-400'}>
                  {'Member not found'}
                </Typography>
              )}
            </Typography>
            {params.data.extSecondaryAgentName && (
              <Typography variant={'body2'} className={'text-gray-400'}>
                {params.data.extSecondaryAgentName}
              </Typography>
            )}
          </Box>
        ),
      },
      {
        colId: 'leadCompany',
        headerName: 'Partner',
        field: 'leadCompany',
        valueGetter: (params) => partnerHumanNames[params.data.leadCompany],
        cellRenderer: (params: ICellRendererParams<AncillaryLead>) => (
          <PartnerChip partner={params.data.leadCompany as LeadCompany} />
        ),
      },
      {
        colId: 'createdAt',
        headerName: 'Generated On',
        field: 'createdAt',
        sort: 'desc',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            // Convert timestamp to date, so ag-grid can compare
            const cellDate = new Date(cellValue)
            const cellDateOnly = new Date(
              cellDate.getFullYear(),
              cellDate.getMonth(),
              cellDate.getDate(),
            )
            if (cellDateOnly < filterLocalDateAtMidnight) {
              return -1
            } else if (cellDateOnly > filterLocalDateAtMidnight) {
              return 1
            } else {
              return 0
            }
          },
        },
        cellRenderer: (params) => (
          <Box className="leading-snug">
            <Box>{dayjs(params.data.createdAt).format('Do MMM YYYY')}</Box>
            <Box className="text-gray-400">
              {dayjs(params.data.createdAt).format('h:mma')}
            </Box>
          </Box>
        ),
      },
      {
        colId: 'leadStatus',
        headerName: 'Status',
        field: 'leadStatus',
        valueGetter: (params) => statusHumanNames[params.data.leadStatus],
        cellRenderer: (params: ICellRendererParams<AncillaryLead>) => (
          <LeadStatusChip status={params.data.leadStatus as LeadStatus} />
        ),
      },
      {
        colId: 'submittedBy',
        headerName: 'Submitted By',
        field: 'triggeredByUser.name',
        comparator: (a, b) => {
          // Ensures undefined values are sorted to the end
          if (a === undefined && b === undefined) return 0
          if (a === undefined) return 1
          if (b === undefined) return -1
          return a.localeCompare(b)
        },
        filter: true,
        cellRenderer: (params: ICellRendererParams<AncillaryLead>) =>
          params.data.triggeredByUser?.name ? (
            <Box className="leading-snug">
              <Box>{params.data.triggeredByUser.name}</Box>
              <Box className="text-gray-400">
                {dayjs(params.data.triggeredAt).format('Do MMM YYYY')}
              </Box>
            </Box>
          ) : (
            <EmptyValue />
          ),
      },
      {
        // Only used for CSV export
        colId: 'triggeredAt',
        headerName: 'Submitted On',
        field: 'triggeredAt',
        hide: true,
      },
      {
        colId: 'actions',
        headerName: 'Actions',
        flex: 1,
        sortable: false,
        cellClass: () => {
          const baseClasses = 'flex justify-center items-center'
          return hasSelectionRef.current
            ? `${baseClasses} invisible`
            : baseClasses
        },
        filter: false,
        cellRenderer: (params: ICellRendererParams<AncillaryLead>) => (
          <ActionCell cellParams={params} setActiveLead={setActiveLead} />
        ),
        cellStyle: () => ({
          border: 'none', // Suppresses focus after clicking refer button
        }),
      },
    ]

    // Shared column configurations
    const defaultColDef = useMemo(
      () => ({
        sortable: true,
        resizable: true,
        cellClass: 'flex items-center',
        flex: isSmallDisplay ? undefined : 1,
        filter: true,
        filterParams: {
          buttons: ['reset'],
        },
      }),
      [isSmallDisplay],
    )

    const onGridReadyLocal = (params: GridReadyEvent) => {
      setGridApi(params.api)
      reorderColumns(params.api, activeTab)
      onGridReady?.(params.api)
    }

    const getRowId = useCallback(
      (params: GetRowIdParams<AncillaryLead>) => params.data.id.toString(),
      [],
    )

    useEffect(() => {
      if (gridApi) reorderColumns(gridApi, activeTab)
    }, [activeTab, gridApi])

    const onSelectionChanged = () => {
      const selectedRows = gridApi?.getSelectedRows() || []
      setSelectedLeadIds(selectedRows.map((lead) => lead.id))
      const hasSelected = selectedRows.length > 0

      // Only update and refresh if the selection status has changed
      if (hasSelectionRef.current !== hasSelected && gridApi) {
        hasSelectionRef.current = hasSelected
        gridApi.refreshCells({ columns: ['actions'] })
      }
    }

    return (
      <div
        className="ag-theme-quartz"
        style={{ height: 'calc(100vh - 230px)', width: '100%' }}
      >
        <AgGridReact
          className={'ancil-table'}
          onGridReady={onGridReadyLocal}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          rowData={ancillaryLeads || []}
          loading={loading}
          autoSizeStrategy={
            isSmallDisplay ? { type: 'fitCellContents' } : undefined
          }
          loadingOverlayComponent={() => <Loading />}
          overlayNoRowsTemplate={
            error
              ? 'Error Loading Leads - Please try again later or contact support'
              : 'No Leads Available'
          }
          pagination
          suppressScrollOnNewData
          paginationPageSize={20}
          getRowId={getRowId}
          rowBuffer={20}
          groupDisplayType="groupRows"
          theme={ancilGridTheme}
          gridOptions={{ rowHeight: 60 }}
          noRowsOverlayComponent={() => <Empty />}
          quickFilterText={query}
          rowClass={'cursor-pointer group'}
          onRowDoubleClicked={({ data }) => setActiveLead(data)}
          rowSelection={{ mode: 'multiRow', selectAll: 'currentPage' }}
          onSelectionChanged={onSelectionChanged}
          groupDefaultExpanded={-1} // expand all groups by default
          suppressCellFocus // disables cell borders appearing on click
          suppressColumnVirtualisation // fixes column flickering issue on horizontal scroll
          loadThemeGoogleFonts={false} // stops console warning
        />
        <Typography
          className={'mt-5 flex items-center leading-none text-gray-400'}
          variant={'subtitle2'}
        >
          <CursorArrowRaysIcon className={'mr-2 h-4 w-4'} />
          {'Double-click a row to refer or view lead details.'}
        </Typography>
      </div>
    )
  },
)

export default AncilTable
