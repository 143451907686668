import React from 'react'

import EastIcon from '@mui/icons-material/East'
import { Box, Alert, Skeleton } from '@mui/material'
import { Timeline } from 'antd'
import dayjs from 'dayjs'
import { AncillaryLeadLog, AncillaryLeadLogVariables } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import LeadStatusChip from 'src/components/Ancil/common/LeadStatusChip'
import { GET_LEAD_LOG } from 'src/components/Ancil/gql/queries'
import { AncillaryLead } from 'src/pages/AncilPage/AncilPage'

type AncillaryLeadLogItem = AncillaryLeadLog['ancillaryLeadLog'][number]

interface Props {
  lead: AncillaryLead
}

export default function LeadStatusTimeline({
  lead,
}: Props): React.ReactElement {
  const { data, loading, error } = useQuery<
    AncillaryLeadLog,
    AncillaryLeadLogVariables
  >(GET_LEAD_LOG, { variables: { leadId: lead.id } })

  if (loading)
    return (
      <div className={'w-full'}>
        <Skeleton variant={'text'} className={'w-[40%]'} />
        <Skeleton variant={'text'} className={'mt-4 w-[100%]'} />
        <Skeleton variant={'text'} className={'mt-2 w-[100%]'} />
        <Skeleton variant={'text'} className={'mt-2 w-[70%]'} />
      </div>
    )

  if (error)
    return (
      <div className={'w-full'}>
        <Alert severity={'error'}>{'Failed to load status history'}</Alert>
      </div>
    )

  const getHeaderText = (log: AncillaryLeadLogItem) => {
    if (log.previousStatus === null) {
      return 'Lead created'
    }

    if (log.previousStatus === 'NEW') {
      return `Lead submitted by ${lead.triggeredByUser.name}`
    }

    if (log.newStatus === 'ARCHIVED') {
      return 'Lead Archived'
    }

    return 'Status updated by partner'
  }

  const getTimestamp = (log: AncillaryLeadLogItem) => {
    if (log.previousStatus === null) {
      return lead.createdAt
    }

    if (log.previousStatus === 'NEW') {
      return lead.triggeredAt
    }

    return log.createdAt
  }

  return (
    <Timeline
      items={data?.ancillaryLeadLog.map((log) => ({
        children: (
          <Box display={'flex'} flexDirection={'column'} gap={1} pt={0.2}>
            <p>{getHeaderText(log)}</p>
            {log.previousStatus && log.newStatus && (
              <Box display={'flex'} alignItems={'center'}>
                <LeadStatusChip status={log.previousStatus} />
                <EastIcon className={'mx-2 h-4 w-4'} />
                <LeadStatusChip status={log.newStatus} />
              </Box>
            )}
            <p className={'text-gray-400'}>
              {dayjs(getTimestamp(log)).format('Do MMM. YYYY, h:mma')}
            </p>
          </Box>
        ),
      }))}
    />
  )
}
