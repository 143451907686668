import React from 'react'

import { Box, Typography } from '@mui/material'
import { Descriptions } from 'antd'
import dayjs from 'dayjs'

import OpportunityChip from 'src/components/Ancil/common/OpportunityChip'
import PartnerChip from 'src/components/Ancil/common/PartnerChip'
import { AdditionalInformationItem } from 'src/components/Ancil/types'
import Modal from 'src/components/Modal/Modal'
import { AncillaryLead } from 'src/pages/AncilPage/AncilPage'

import LeadStatusTimeline from '../common/LeadStatusTimeline'

interface Props {
  open: boolean
  onClose: () => void
  lead: AncillaryLead
}

export default function LeadDetailsModal({
  open,
  onClose,
  lead,
}: Props): React.ReactElement {
  const {
    id,
    decryptedName,
    triggerType,
    triggeredAt,
    leadCompany,
    propertyAddress,
    triggeredByUser,
    extPrimaryAgentName,
    extSecondaryAgentName,
    createdAt,
    additionalPayloadData,
  } = lead
  const staffMemberLabel = `${extPrimaryAgentName}${extSecondaryAgentName ? `, ${extSecondaryAgentName}` : ''}`

  return (
    <Modal
      title={'Lead Details'}
      open={open}
      onClose={onClose}
      className={'lead-details-modal mt-20 !w-[1000px]'}
      childClassName={''}
      headerClassName={'!border-b-1 !border-[#F3F4F6] !pl-6'}
    >
      <Box display={'flex'}>
        <Box flex={14} p={3} pt={2.5}>
          <Box>
            <Typography variant="h4" className={'text-3xl'}>
              {decryptedName}
            </Typography>
            <Typography className="mt-2">{propertyAddress}</Typography>
          </Box>
          <Box className="mt-3" gap={1} display="flex">
            <OpportunityChip opportunity={triggerType} />
            <PartnerChip partner={leadCompany} />
          </Box>
          <Box className="mt-5">
            <Descriptions size="small" layout="horizontal" column={1} bordered>
              <Descriptions.Item
                label={
                  !extSecondaryAgentName ? 'Staff Member' : 'Staff Members'
                }
                labelStyle={{ width: 150 }}
              >
                {extPrimaryAgentName ? (
                  staffMemberLabel
                ) : (
                  <Typography variant={'body2'} className={'text-gray-400'}>
                    {'Member not found'}
                  </Typography>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Generated on">
                {dayjs(createdAt).format('Do MMM YYYY, h:mma')}
              </Descriptions.Item>
              <Descriptions.Item label="Submitted by">
                {triggeredByUser?.name || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Submitted on">
                {triggeredAt
                  ? dayjs(triggeredAt).format('Do MMM YYYY, h:mma')
                  : '-'}
              </Descriptions.Item>
              {Array.isArray(additionalPayloadData) &&
                additionalPayloadData?.map(
                  (data: AdditionalInformationItem) => (
                    <Descriptions.Item
                      label={data.label}
                      key={data.label}
                      labelStyle={{ width: 150 }}
                    >
                      {data.dataType === 'DATE'
                        ? dayjs(data.value).format('Do MMM YYYY')
                        : data.value}
                    </Descriptions.Item>
                  ),
                )}
            </Descriptions>
          </Box>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            className={'mt-7'}
          >
            <Typography>Consent Acknowledgement</Typography>
            <Typography
              variant={'subtitle2'}
              style={{ fontSize: 12 }}
              className={'text-slate-400'}
            >
              {`Consent Acknowledged ${dayjs(triggeredAt).format('Do MMM YYYY, h:mma')}`}
            </Typography>
          </Box>
          <Box
            display="flex"
            className="mt-3 bg-slate-100"
            flexDirection={'row'}
            alignItems={'center'}
            p={1.5}
            borderRadius={2}
            gap={1.5}
          >
            <Typography style={{ fontSize: 14 }} className="text-slate-600">
              The client provided their informed consent for their details to be
              used in connection with this lead. This confirmation ensured the
              information was shared in accordance with their authorisation and
              expectations.
            </Typography>
          </Box>
        </Box>
        <Box
          style={{
            borderLeft: '1px solid #F3F4F6',
            maxHeight: 700,
            overflow: 'scroll',
          }}
          flex={10}
          p={3}
          display={'flex'}
        >
          <LeadStatusTimeline lead={lead} />
        </Box>
      </Box>
    </Modal>
  )
}
