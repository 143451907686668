import { useMutation } from '@apollo/client'
import { RestoreLead, RestoreLeadVariables } from 'types/graphql'

import { toast } from '@redwoodjs/web/toast'

import { RESTORE_LEAD } from '../gql/mutations'
import { ANCIL_TABLE_QUERY } from '../gql/queries'

type RestoreLeadConfig = Parameters<
  typeof useMutation<RestoreLead, RestoreLeadVariables>
>[1]

export function useRestoreLead(config?: RestoreLeadConfig) {
  return useMutation<RestoreLead, RestoreLeadVariables>(RESTORE_LEAD, {
    onCompleted: () => {
      toast.success('Lead restored successfully')
    },
    onError: () => {
      toast.error('Failed to restore lead')
    },
    awaitRefetchQueries: true, // defaults
    refetchQueries: [ANCIL_TABLE_QUERY], // defaults
    ...config,
  })
}
