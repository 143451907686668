import React from 'react'

import { Avatar, Chip, ChipProps } from '@mui/material'
import { LeadCompany } from 'types/graphql'

interface Props extends ChipProps {
  partner: LeadCompany
}

export const partnerHumanNames: Record<LeadCompany, string> = {
  ['ON_COVER']: 'On Cover Insurance',
  ['COMPARE_CONNECT']: 'Compare & Connect Connections',
  ['RELLO']: 'Rello Funding',
  ['INNOVAYT']: 'Innovayt Finance',
  ['BOND']: 'Bond Conveyancing',
}

export default function PartnerChip({
  partner,
  className: additionalClassNames,
  ...rest
}: Props): React.ReactElement {
  const partnerHumanNames: Record<LeadCompany, string> = {
    ['ON_COVER']: 'On Cover Insurance',
    ['COMPARE_CONNECT']: 'Compare & Connect Connections',
    ['RELLO']: 'Rello Funding',
    ['INNOVAYT']: 'Innovayt Finance',
    ['BOND']: 'Bond Conveyancing',
  }

  const partnerAvatars: Record<LeadCompany, React.ReactElement> = {
    ['ON_COVER']: (
      <Avatar className={'!mr-0.5 bg-[#00928A] !text-white'}>O</Avatar>
    ),
    ['COMPARE_CONNECT']: (
      <Avatar className={'!mr-0.5 bg-[#1DA061] !text-white'}>C</Avatar>
    ),
    ['RELLO']: (
      <Avatar className={'!mr-0.5 bg-[#073153] !text-white'}>R</Avatar>
    ),
    ['INNOVAYT']: (
      <Avatar className={'!mr-0.5 bg-[#00B9F2] !text-white'}>I</Avatar>
    ),
    ['BOND']: <Avatar className={'!mr-0.5 bg-[#014E90] !text-white'}>B</Avatar>,
  }

  return (
    <Chip
      size={'small'}
      label={partnerHumanNames[partner] || partner}
      avatar={partnerAvatars[partner]}
      className={`rounded-xl bg-transparent py-2 capitalize ${additionalClassNames}`}
      {...rest}
    />
  )
}
