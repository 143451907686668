export const REFER_LEADS = gql`
  mutation ReferLeads($input: [TriggerAncillaryLeadInput!]!) {
    triggerAncillaryLeads(inputs: $input) {
      id
    }
  }
`

export const ARCHIVE_LEADS = gql`
  mutation ArchiveLeads($ids: [Int!]!) {
    archiveAncillaryLeads(ids: $ids)
  }
`

export const RESTORE_LEAD = gql`
  mutation RestoreLead($id: Int!) {
    restoreAncillaryLead(id: $id) {
      id
    }
  }
`
