import React from 'react'

import { Box, Chip, ChipProps } from '@mui/material'
import { AncillaryLeadTriggerType } from 'types/graphql'
interface Props extends ChipProps {
  opportunity: AncillaryLeadTriggerType
}

export const opportunityHumanNames: Record<AncillaryLeadTriggerType, string> = {
  ['MANUAL']: 'Manual',
  ['OFFER_RECEIVED']: 'Offer Received',
  ['DRAFT_CONTRACT']: 'Contract Drafted',
  ['ACCEPTED_CONTRACT']: 'Contract Accepted',
  ['CONDITIONAL_CONTRACT']: 'Conditional Contract',
  ['UNCONDITIONAL_CONTRACT']: 'Unconditional Contract',
  ['FALLEN_CONTRACT']: 'Contract Crashed',
  ['TENANCY_APPLICATION']: 'Tenancy Application Received',
  ['TENANCY_APPLICATION_APPROVED']: 'Tenancy Application Approved',
  ['TENANCY_STARTED']: 'Tenancy Started',
  ['TENANCY_ENDED']: 'Tenancy Ended',
  ['MANAGEMENT_APPOINTMENT']: 'Management Appointment',
}

export default function OpportunityChip({
  opportunity,
  className: additionalClassNames,
  ...rest
}: Props): React.ReactElement {
  const opportunityClassNames: Record<AncillaryLeadTriggerType, string> = {
    ['MANUAL']: 'bg-blue-500 !text-slate-600 !border-blue-700',

    ['OFFER_RECEIVED']: 'bg-blue-500 !text-blue-600 !border-blue-700',

    ['DRAFT_CONTRACT']: 'bg-blue-500 !border-blue-700',
    ['ACCEPTED_CONTRACT']: 'bg-emerald-500 !border-emerald-700',
    ['CONDITIONAL_CONTRACT']: 'bg-orange-500 !border-orange-700',
    ['UNCONDITIONAL_CONTRACT']: 'bg-orange-500 !border-orange-700',
    ['FALLEN_CONTRACT']: 'bg-red-500 !border-red-700',

    ['TENANCY_APPLICATION']: 'bg-blue-500 !border-blue-700',
    ['TENANCY_APPLICATION_APPROVED']: 'bg-emerald-500 !border-emerald-700',
    ['TENANCY_STARTED']: 'bg-emerald-500 !border-emerald-700',
    ['TENANCY_ENDED']: 'bg-red-500 !border-red-700',

    ['MANAGEMENT_APPOINTMENT']: 'bg-blue-500 !border-blue-700',
  }
  return (
    <Chip
      size={'small'}
      label={opportunityHumanNames[opportunity] || opportunity}
      className={`rounded-md !border-gray-200 bg-gray-50 py-2 capitalize !text-gray-600 ${additionalClassNames}`}
      icon={
        <Box
          className={`!ml-2 !mr-0.5 !h-2 !w-2 rounded-sm ${opportunityClassNames[opportunity]}`}
        />
      }
      variant={'outlined'}
      {...rest}
    />
  )
}
