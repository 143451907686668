import React, { useEffect, useState } from 'react'

import { Box, Checkbox, Typography, Divider } from '@mui/material'
import { Descriptions } from 'antd'
import dayjs, { Dayjs } from 'dayjs'

import { toast } from '@redwoodjs/web/toast'

import AdditionalRequirementsForm from 'src/components/Ancil/common/AdditionalRequirementsForm'
import OpportunityChip from 'src/components/Ancil/common/OpportunityChip'
import PartnerChip from 'src/components/Ancil/common/PartnerChip'
import { additionalRequiredInformation } from 'src/components/Ancil/utils'
import Modal from 'src/components/Modal'
import { AncillaryLead } from 'src/pages/AncilPage/AncilPage'

import { useReferLeads } from '../hooks/useReferLeads'

export const CONSENT_CHECKBOX_DATA_TEST_ID = 'consent-checkbox'

interface Props {
  open: boolean
  onClose: () => void
  lead: AncillaryLead
}

export default function ReferLeadModal({
  open,
  onClose,
  lead,
}: Props): React.ReactElement {
  const [payload, setPayload] = useState<
    Record<string, string | number | Dayjs>
  >({})
  const [attemptedSubmit, setAttemptedSubmit] = useState(false)
  const {
    hasConsent,
    decryptedName,
    triggerType,
    triggeredByUser,
    leadCompany,
    leadType,
    propertyAddress,
    extPrimaryAgentName,
    extSecondaryAgentName,
    createdAt,
  } = lead
  const [consentChecked, setConsentChecked] = useState(hasConsent)

  const [referLead, { loading }] = useReferLeads({
    onCompleted: () => {
      onClose()
      toast.success('Successfully referred lead')
    },
  })

  const staffMemberLabel = `${extPrimaryAgentName}${extSecondaryAgentName ? `, ${extSecondaryAgentName}` : ''}`
  const hasLeadBeenSubmitted = !!triggeredByUser
  const additionalRequirements =
    additionalRequiredInformation[leadCompany]?.[leadType] || []

  useEffect(() => {
    setConsentChecked(lead.hasConsent)
  }, [lead])

  useEffect(() => {
    if (!additionalRequirements.length) return
    setPayload(
      additionalRequirements.reduce(
        (acc, req) => {
          acc[req.dataKey] = ''
          return acc
        },
        {} as Record<string, string | number | Dayjs>,
      ),
    )
  }, [additionalRequirements])

  const handleClose = () => {
    setPayload({})
    setAttemptedSubmit(false)
    onClose()
  }

  const handleRefer = () => {
    setAttemptedSubmit(true)

    const missingRequired = additionalRequirements.some(
      (req) => req.required && !payload[req.dataKey],
    )

    if (missingRequired || !consentChecked) return

    // DayJS to ISOString + add value to payload
    const serializedPayload = additionalRequirements.map((req) => {
      let value = payload[req.dataKey]
      if (dayjs.isDayjs(value)) {
        value = value.toISOString()
      }
      return {
        ...req,
        value,
      }
    })

    return referLead({
      variables: {
        input: [
          {
            leadId: lead.id,
            hasConsent: true,
            additionalPayloadData: serializedPayload,
          },
        ],
      },
    })
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={'Refer Lead'}
      className="mt-20 !w-[600px]"
      closeButtonVisible
      footerVisible
      confirmText="Refer Lead"
      cancelText="Cancel"
      onConfirm={handleRefer}
      onCancel={handleClose}
      confirmDisabled={!consentChecked}
      loading={loading}
    >
      <Box p={3} pt={1}>
        <Box>
          <Typography variant="h4" className={'text-3xl'}>
            {decryptedName}
          </Typography>
          <Typography className="mt-2">{propertyAddress}</Typography>
        </Box>
        <Box className="mt-3" gap={1} display="flex">
          <OpportunityChip opportunity={triggerType} />
          <PartnerChip partner={leadCompany} />
        </Box>
        <Box className="mt-5">
          <Descriptions size="small" layout="horizontal" column={1} bordered>
            <Descriptions.Item
              label={!extSecondaryAgentName ? 'Staff Member' : 'Staff Members'}
              labelStyle={{ width: 150 }}
            >
              {extPrimaryAgentName ? (
                staffMemberLabel
              ) : (
                <Typography variant={'body2'} className={'text-gray-400'}>
                  {'Member not found'}
                </Typography>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Generated on">
              {dayjs(createdAt).format('Do MMM YYYY, h:mma')}
            </Descriptions.Item>
          </Descriptions>
        </Box>
        {!hasLeadBeenSubmitted && (
          <AdditionalRequirementsForm
            payload={payload}
            additionalRequirements={additionalRequirements}
            onPayloadChange={setPayload}
            attemptedSubmit={attemptedSubmit}
          />
        )}
        <Divider className={'mb-5 mt-5'} />
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography>Consent Acknowledgement</Typography>
        </Box>
        <Box
          display="flex"
          className="mt-3 cursor-pointer bg-slate-100"
          flexDirection={'row'}
          alignItems={'center'}
          p={1.5}
          borderRadius={2}
          gap={1.5}
          onClick={() => setConsentChecked(!consentChecked)}
        >
          <Checkbox
            data-testid={CONSENT_CHECKBOX_DATA_TEST_ID}
            checked={consentChecked}
          />
          <Typography style={{ fontSize: 14 }} className="text-slate-600">
            The client has provided their informed consent for their details to
            be used in connection with this lead. By proceeding, you confirm
            that the client has authorised the sharing of their information for
            the purposes outlined.
          </Typography>
        </Box>
      </Box>
    </Modal>
  )
}
