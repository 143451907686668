import { useMutation } from '@apollo/client'
import { ArchiveLeads, ArchiveLeadsVariables } from 'types/graphql'

import { toast } from '@redwoodjs/web/toast'

import { ARCHIVE_LEADS } from '../gql/mutations'
import { ANCIL_TABLE_QUERY } from '../gql/queries'

type ArchiveLeadsConfig = Parameters<
  typeof useMutation<ArchiveLeads, ArchiveLeadsVariables>
>[1]

export function useArchiveLeads(config?: ArchiveLeadsConfig) {
  return useMutation<ArchiveLeads, ArchiveLeadsVariables>(ARCHIVE_LEADS, {
    onCompleted: () => {
      toast.success('Lead archived successfully')
    },
    onError: () => {
      toast.error('Failed to archive lead')
    },
    awaitRefetchQueries: true, // defaults
    refetchQueries: [ANCIL_TABLE_QUERY], // defaults
    ...config,
  })
}
