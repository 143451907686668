import React from 'react'

import { Chip, ChipProps } from '@mui/material'
import { LeadStatus } from 'types/graphql'

interface Props extends ChipProps {
  status: LeadStatus
}

export const statusHumanNames: Record<LeadStatus, string> = {
  ['NEW']: 'New',

  ['PENDING']: 'Pending',
  ['WITH_REFERRER']: 'With Referrer',

  ['CONTACTED']: 'Contacted',
  ['IN_PROGRESS']: 'In Progress',
  ['PIPELINE']: 'Pipeline',

  ['UNQUALIFIED']: 'Unqualified',

  ['GONE_QUIET']: 'Gone Quiet',
  ['UNCONTACTABLE']: 'Uncontactable',
  ['CLOSED_WON']: 'Closed Won',
  ['CLOSED_LOST']: 'Closed Lost',
  ['CANCELLED']: 'Cancelled',

  ['ARCHIVED']: 'Archived',

  // Shouldn't ever come through to the front end, just here to satisfy types
  ['BAD_DATA']: 'Bad Data',
  ['PURGED']: 'PURGED',
}

export default function LeadStatusChip({
  status,
  className: additionalClassNames,
  ...rest
}: Props): React.ReactElement {
  if (!status) return <></>
  const statusClassNames: Partial<Record<LeadStatus, string>> = {
    ['NEW']: 'bg-teal-100 text-teal-600',

    ['PENDING']: 'bg-indigo-100 text-indigo-600',
    ['WITH_REFERRER']: 'bg-indigo-100 text-indigo-600',

    ['CONTACTED']: 'bg-sky-100 text-sky-600',
    ['IN_PROGRESS']: 'bg-sky-100 text-sky-600',
    ['PIPELINE']: 'bg-sky-100 text-sky-600',

    ['UNQUALIFIED']: 'bg-orange-100 text-orange-600',

    ['GONE_QUIET']: 'bg-red-100 text-red-600',
    ['UNCONTACTABLE']: 'bg-red-100 text-red-600',
    ['CLOSED_WON']: 'bg-green-100 text-green-600',
    ['CLOSED_LOST']: 'bg-red-100 text-red-600',
    ['CANCELLED']: 'bg-red-100 text-red-600',

    ['ARCHIVED']: 'bg-gray-100 text-gray-600',
  }

  return (
    <Chip
      size={'small'}
      label={status.replaceAll('_', ' ').toLowerCase()}
      className={`rounded-xl px-1 py-2 capitalize ${statusClassNames[status] || 'bg-gray-300 text-black'} ${additionalClassNames}`}
      {...rest}
    />
  )
}
