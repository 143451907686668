import { useMutation } from '@apollo/client'
import {
  ReferLeads,
  ReferLeadsVariables,
  TriggerAncillaryLeadInput,
} from 'types/graphql'

import { toast } from '@redwoodjs/web/toast'

import { REFER_LEADS } from '../gql/mutations'
import { ANCIL_TABLE_QUERY } from '../gql/queries'

type ReferLeadsConfig = Parameters<
  typeof useMutation<ReferLeads, ReferLeadsVariables>
>[1]

export function useReferLeads(config?: ReferLeadsConfig) {
  // type assertion required a few times here because of how the gql codegen types the input
  const input = config?.variables
    ?.input as unknown as Array<TriggerAncillaryLeadInput>
  const plural = input?.length > 1 ? 's' : '' // helper for the toast message

  return useMutation<ReferLeads, ReferLeadsVariables>(REFER_LEADS, {
    onCompleted: () => {
      toast.success(`Successfully referred lead${plural}`)
    },
    onError: () => {
      toast.error(`Failed to refer lead${plural}`)
    },
    awaitRefetchQueries: true, // defaults
    refetchQueries: [ANCIL_TABLE_QUERY], // defaults
    ...config,
  })
}
