import React from 'react'

import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import { GridApi } from 'ag-grid-community'
import dayjs from 'dayjs'

import Button from 'src/components/Library/Button'
import { AncillaryLead } from 'src/pages/AncilPage/AncilPage'

interface Props {
  gridApi?: GridApi<AncillaryLead> | null
}

export default function CSVDownloadButton({
  gridApi,
}: Props): React.ReactElement {
  const COLUMNS_TO_EXPORT = [
    'decryptedName',
    'triggerType',
    'associatedAddress',
    'extPrimaryAgentName',
    'staffMembers',
    'leadCompany',
    'createdAt',
    'leadStatus',
    'submittedBy',
    'triggeredAt',
  ]

  // Format date columns here to not conflict with sorting comparison
  // in the AncilTable component
  const processCellCallback = (params) => {
    if (params.column.getColId() === 'createdAt') {
      return params.value ? dayjs(params.value).format('Do MMM. YYYY') : ''
    }
    if (params.column.getColId() === 'triggeredAt') {
      return params.value ? dayjs(params.value).format('Do MMM. YYYY') : ''
    }
    return params.value || ''
  }

  return (
    <Button
      variant={'outlined'}
      className={'text-indigo-500'}
      startIcon={<CloudDownloadOutlinedIcon />}
      onClick={() =>
        gridApi?.exportDataAsCsv({
          fileName: `referral-tracker-export-${dayjs().format('YYYY-MM-DD')}.csv`,
          columnKeys: COLUMNS_TO_EXPORT,
          processCellCallback,
        })
      }
    >
      {'Download CSV'}
    </Button>
  )
}
