import React from 'react'

import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { Box, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs'

import { AdditionalRequirement } from 'src/components/Ancil/utils'

interface AdditionalRequirementsFormProps {
  additionalRequirements: AdditionalRequirement[]
  payload: Record<string, string | number | Dayjs>
  onPayloadChange: (payload: Record<string, string | number | Dayjs>) => void
  attemptedSubmit: boolean
}

export default function AdditionalRequirementsForm({
  additionalRequirements,
  onPayloadChange,
  payload,
  attemptedSubmit,
}: AdditionalRequirementsFormProps): React.ReactElement | null {
  if (!additionalRequirements.length) return null

  return (
    <>
      <Box display="flex" className="mt-5" borderRadius={2}>
        <InformationCircleIcon className="-mb-1 mr-1.5 h-5 w-5 text-gray-700" />
        <Typography style={{ fontSize: 14 }} className="text-gray-700">
          This lead requires some extra details before it can be referred
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={2} className="mt-5">
        {additionalRequirements.map(
          ({ label, dataType, dataKey, required }) => {
            const showError = attemptedSubmit && required && !payload[dataKey]
            switch (dataType) {
              case 'STRING':
                return (
                  <Box key={dataKey} className="w-100">
                    <TextField
                      size="small"
                      label={label}
                      variant="outlined"
                      fullWidth
                      required={required}
                      value={payload[dataKey] || ''}
                      onChange={(e) =>
                        onPayloadChange({
                          ...payload,
                          [dataKey]: e.target.value,
                        })
                      }
                      error={showError}
                      helperText={
                        showError ? `${label} is required` : undefined
                      }
                    />
                  </Box>
                )
              case 'NUMBER':
                return (
                  <Box key={dataKey}>
                    <TextField
                      size="small"
                      label={label}
                      variant="outlined"
                      fullWidth
                      required={required}
                      type="number"
                      value={payload[dataKey] || ''}
                      onChange={(e) =>
                        onPayloadChange({
                          ...payload,
                          [dataKey]: e.target.value,
                        })
                      }
                      error={showError}
                      helperText={
                        showError ? `${label} is required` : undefined
                      }
                    />
                  </Box>
                )
              case 'DATE':
                return (
                  <Box key={dataKey}>
                    <DatePicker
                      label={label}
                      value={payload[dataKey] ? dayjs(payload[dataKey]) : null}
                      onChange={(date: Dayjs | null) =>
                        onPayloadChange({ ...payload, [dataKey]: date || '' })
                      }
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                          required,
                          error: showError,
                          helperText: showError
                            ? `${label} is required`
                            : undefined,
                        },
                      }}
                    />
                  </Box>
                )
              default:
                return (
                  <Box key={dataKey}>
                    <TextField
                      size="small"
                      label={label}
                      variant="outlined"
                      fullWidth
                      required={required}
                      value={payload[dataKey] || ''}
                      onChange={(e) =>
                        onPayloadChange({
                          ...payload,
                          [dataKey]: e.target.value,
                        })
                      }
                      error={showError}
                      helperText={
                        showError ? `${label} is required` : undefined
                      }
                    />
                  </Box>
                )
            }
          },
        )}
      </Box>
    </>
  )
}
