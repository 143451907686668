import React, { useState } from 'react'

import { Box, Checkbox, Divider, Typography } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { TriggerAncillaryLeadInput } from 'types/graphql'

import { toast } from '@redwoodjs/web/toast'

import AdditionalRequirementsForm from 'src/components/Ancil/common/AdditionalRequirementsForm'
import PartnerChip from 'src/components/Ancil/common/PartnerChip'
import { useReferLeads } from 'src/components/Ancil/hooks/useReferLeads'
import { additionalRequiredInformation } from 'src/components/Ancil/utils'
import Modal from 'src/components/Modal'
import { AncillaryLead } from 'src/pages/AncilPage/AncilPage'

import OpportunityChip from '../common/OpportunityChip'

import SummaryTable from './SummaryTable'

export const CONSENT_CHECKBOX_DATA_TEST_ID = 'consent-checkbox'

interface Props {
  open: boolean
  onClose: (options: { clearSelectedRows: boolean }) => void
  leads: AncillaryLead[]
}

interface LeadPayload {
  [key: string]: string | number | Dayjs
}

export default function BulkReferLeadModal({
  open,
  onClose,
  leads,
}: Props): React.ReactElement {
  const [consentChecked, setConsentChecked] = useState(false)
  const [attemptedSubmit, setAttemptedSubmit] = useState(false)
  const [payloadByLeadId, setPayloadByLeadId] = useState<
    Record<number, LeadPayload>
  >({})

  const [referLeads, { loading }] = useReferLeads({
    onCompleted: () => {
      handleClose()
      toast.success('Successfully referred leads')
    },
  })

  const handleClose = (options = { clearSelectedRows: true }) => {
    onClose(options)
    setAttemptedSubmit(false)
    setConsentChecked(false)
    setPayloadByLeadId({})
  }

  const handleRefer = () => {
    setAttemptedSubmit(true)

    if (!consentChecked) return

    for (const lead of leads) {
      const { leadCompany, leadType, id } = lead
      const additionalReqs =
        additionalRequiredInformation[leadCompany]?.[leadType] || []
      if (additionalReqs.length > 0) {
        const currentPayload = payloadByLeadId[id] || {}
        const missingRequired = additionalReqs.some(
          (req) => req.required && !currentPayload[req.dataKey],
        )
        if (missingRequired) {
          return
        }
      }
    }

    const finalInputs = leads.map((lead) => {
      const { leadCompany, leadType, id } = lead
      const additionalReqs =
        additionalRequiredInformation[leadCompany]?.[leadType] || []
      const currentPayload = payloadByLeadId[id] || {}
      const serializedPayload = additionalReqs.map((req) => {
        let value = currentPayload[req.dataKey]
        if (dayjs.isDayjs(value)) {
          value = value.toISOString()
        }
        return {
          ...req,
          value,
        }
      })
      return {
        leadId: id,
        hasConsent: true,
        additionalPayloadData: serializedPayload,
      }
    })

    return referLeads({
      variables: {
        input: finalInputs as unknown as TriggerAncillaryLeadInput[],
      },
    })
  }

  const handlePayloadChange = (leadId: number, newPayload: LeadPayload) => {
    setPayloadByLeadId((prev) => ({
      ...prev,
      [leadId]: newPayload,
    }))
  }

  return (
    <Modal
      open={open}
      loading={loading}
      className="mt-20 !w-[1000px]"
      closeButtonVisible={false}
      onConfirm={handleRefer}
      onClose={handleClose}
      onCancel={() => handleClose({ clearSelectedRows: false })}
      confirmText={`Refer ${leads?.length} Leads`}
      confirmDisabled={!consentChecked}
      cancelText="Cancel"
      footerVisible
    >
      <Box p={3} pt={1}>
        <Typography variant={'h6'} className={'mb-2'}>
          Bulk Lead Referring
        </Typography>
        <Typography className={'text-sm text-gray-500'}>
          Bulk Lead Referral allows you to refer multiple leads at once. Please
          review the leads below and confirm that you have obtained the
          necessary consent from the client.
        </Typography>
        <SummaryTable leads={leads} />
        {leads.map((lead) => {
          const {
            decryptedName,
            propertyAddress,
            leadCompany,
            leadType,
            triggerType,
            id,
          } = lead
          const additionalReqs =
            additionalRequiredInformation[leadCompany]?.[leadType] || []
          if (!additionalReqs.length) return null

          return (
            <Box key={id} mb={3} pl={3} className={'border-l-2'}>
              <Typography variant="subtitle1" className="mb-2">
                {decryptedName} • {propertyAddress}
              </Typography>
              <Box gap={1} display={'flex'}>
                <OpportunityChip opportunity={triggerType} />
                <PartnerChip partner={leadCompany} />
              </Box>
              <AdditionalRequirementsForm
                payload={payloadByLeadId[id] || {}}
                additionalRequirements={additionalReqs}
                onPayloadChange={(newPayload) =>
                  handlePayloadChange(id, newPayload)
                }
                attemptedSubmit={attemptedSubmit}
              />
            </Box>
          )
        })}
        <Divider className="my-3" />
        <Box
          display="flex"
          flexDirection={'row'}
          alignItems={'center'}
          className="cursor-pointer bg-slate-100"
          p={1.5}
          borderRadius={2}
          gap={1.5}
          onClick={() => setConsentChecked(!consentChecked)}
        >
          <Checkbox
            data-testid={CONSENT_CHECKBOX_DATA_TEST_ID}
            checked={consentChecked}
          />
          <Typography style={{ fontSize: 14 }} className="text-slate-600">
            The clients have provided their informed consent for their details
            to be used in connection with these leads. By proceeding, you
            confirm that the clients have authorised the sharing of their
            information for the purposes outlined.
          </Typography>
        </Box>
      </Box>
    </Modal>
  )
}
