import React, { Dispatch, SetStateAction } from 'react'

import { ArchiveBoxIcon } from '@heroicons/react/24/outline'
import RestoreIcon from '@mui/icons-material/Restore'
import { Box } from '@mui/material'
import { ICellRendererParams } from 'ag-grid-community'
import { Popconfirm } from 'antd'

import { useArchiveLeads } from 'src/components/Ancil/hooks/useArchiveLeads'
import { useRestoreLead } from 'src/components/Ancil/hooks/useRestoreLead'
import Button from 'src/components/Library/Button/Button'
import IconButton from 'src/components/Library/IconButton'
import { AncillaryLead } from 'src/pages/AncilPage/AncilPage'

interface Props {
  cellParams: ICellRendererParams<AncillaryLead>
  setActiveLead: Dispatch<SetStateAction<AncillaryLead>>
}

export default function ActionCell({
  cellParams: { data: lead },
  setActiveLead,
}: Props): React.ReactElement {
  const hasBeenTriggered = lead.triggeredAt
  const [archiveLeads, { loading: archiveLeadsLoading }] = useArchiveLeads()
  const [restoreLead, { loading: restoreLeadLoading }] = useRestoreLead()
  const isLeadArchived = lead.leadStatus === 'ARCHIVED'

  return (
    <Box display={'flex'} alignItems={'center'}>
      {!hasBeenTriggered && (
        <Button
          className={'mr-2.5 !bg-indigo-500'}
          onClick={() => setActiveLead(lead)}
        >
          {'Refer Lead'}
        </Button>
      )}
      {hasBeenTriggered && (
        <Button
          variant={'outlined'}
          onClick={() => setActiveLead(lead)}
          className={'border-none'}
          sx={{
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          {'View Details'}
        </Button>
      )}
      {!isLeadArchived && (
        <Popconfirm
          title={'Archive this Lead?'}
          description={'You can restore this lead later'}
          placement={'left'}
          okButtonProps={{ loading: archiveLeadsLoading }}
          onConfirm={() => archiveLeads({ variables: { ids: [lead.id] } })}
        >
          <IconButton
            color={'primary'}
            className={
              'opacity-0 transition-opacity duration-0 group-hover:opacity-100'
            }
            sx={{
              '&:hover': {
                backgroundColor: 'transparent !important',
              },
            }}
          >
            <ArchiveBoxIcon className={'h-4 w-4'} />
          </IconButton>
        </Popconfirm>
      )}
      {isLeadArchived && (
        <Popconfirm
          title={'Restore this Lead?'}
          description={
            'It will be restored to the status it was in before archiving'
          }
          placement={'left'}
          okButtonProps={{ loading: restoreLeadLoading }}
          onConfirm={() => restoreLead({ variables: { id: lead.id } })}
        >
          <IconButton
            color={'primary'}
            className={
              'opacity-0 transition-opacity duration-0 group-hover:opacity-100'
            }
            sx={{
              '&:hover': {
                backgroundColor: 'transparent !important',
              },
            }}
          >
            <RestoreIcon className={'h-5 w-5'} />
          </IconButton>
        </Popconfirm>
      )}
    </Box>
  )
}
